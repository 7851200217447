import React, { useEffect, useState } from "react";
import LoadingSpinner from "../components/common/loader/LoadingSpinner";

import MatchList from "../components/common/match/MatchList";
import MatchService from "../services/api/match.service";
import HomePageModal from "../components/common/modal/HomePageModal";
import { useCookies } from "react-cookie";
import moment from "moment";

const Home = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [matchList, setMatchList] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [cookies, setCookie] = useCookies(["homemodal"]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getLiveMatchList();
      if (!cookies.homemodal) {
        setModalShow(true);
      }
    }

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (window.Echo) {
      window.Echo.channel("reload_matches").listen("MatchEvents", (e) => {
        // console.log("WS");
        getLiveMatchList();
      });

      return () => {
        window.Echo.leaveChannel("reload_matches");
      };
    }
  }, [matchList]);

  useEffect(() => {
    if (window.Echo) {
      window.Echo.channel("bet-ratio-change").listen("MatchEvents", (e) => {
        // console.log(e);
        if (matchList && matchList.length > 0) {
          let matchListDummy = [...matchList];
          matchListDummy.map((match) => {
            match.bets.map((bet) => {
              if (bet.id == e.betId) {
                bet.bet_events.map((betEvent, index) => {
                  const eventData = e.data;
                  const item = eventData.find(
                    (item) => item.id === betEvent.id
                  );
                  // console.log("item", item);
                  if (item) {
                    if (betEvent.bet_ratio < item.bet_ratio) {
                      bet.bet_events[index].indicator = "up";
                    } else if (betEvent.bet_ratio > item.bet_ratio) {
                      bet.bet_events[index].indicator = "down";
                    }
                    bet.bet_events[index].bet_ratio = item.bet_ratio;
                  }
                });
              }
            });
          });
          // console.log("matchListDummy", matchListDummy);
          setMatchList(matchListDummy);
        }
      });

      return () => {
        window.Echo.leaveChannel("bet-ratio-change");
      };
    }
  }, [matchList]);

  useEffect(() => {
    if (window.Echo) {
      window.Echo.channel("match-bet-update").listen("MatchEvents", (e) => {
        // console.log(e);
        if (matchList && matchList.length > 0) {
          let matchListDummy = [...matchList];
          matchListDummy.map((match, index) => {
            if (match.id === e.match_id) {
              matchListDummy[index].bets = e.data;
            }
          });
          setMatchList(matchListDummy);
        }
      });

      return () => {
        window.Echo.leaveChannel("match-bet-update");
      };
    }
  }, [matchList]);

  const getLiveMatchList = () => {
    setIsLoaded(false);
    MatchService.getLiveMatchList().then(
      (data) => {
        if (data) {
          // console.log('getLiveMatchList', data);
          setMatchList(data);
        }
        setIsLoaded(true);
        return Promise.resolve();
      },
      (error) => {
        // console.log(error);
        setIsLoaded(true);
        return Promise.reject();
      }
    );
  };

  const closeModal = () => {
    setModalShow(false);
    setCookie("homemodal", false, {
      expires: new Date(moment().add(7, "d").format()),
    });
  };

  return (
    <>
      <HomePageModal show={modalShow} onHide={() => closeModal()} />
      {isLoaded ? <MatchList matchList={matchList} /> : <LoadingSpinner />}
    </>
  );
};
export default Home;
