import { apiPrefix } from "../../config/config";
import { authApi } from "../interceptor/auth.interceptor";

class UserService {

    static async getProfile() {
        return await authApi
            .get("/" + apiPrefix + "/user/profile")
            .then((response) => {
                return response.data.data;
            });
    }

    static async getCoins() {
        return await authApi
            .get("/" + apiPrefix + "/user/coins")
            .then((response) => {
                return response.data.data;
            });
    }

    static async getBetHistory(page = 1) {
        return await authApi
            .get("/" + apiPrefix + "/user/profile/bet-history?page=" + page)
            .then((response) => {
                return response.data.data;
            });
    }

    static async getFollowers(page = 1) {
        return await authApi
            .get("/" + apiPrefix + "/user/profile/followers?page=" + page)
            .then((response) => {
                return response.data.data;
            });
    }

    static async getComplainHistory(page = 1) {
        return await authApi
            .get("/" + apiPrefix + "/user/profile/complain-history?page=" + page)
            .then((response) => {
                return response.data.data;
            });
    }

    static async updateClub(formData) {
        return await authApi
            .post("/" + apiPrefix + "/user/profile/change-club", formData)
            .then((response) => {
                // console.log(response);
                return response?.data?.data;
            });
    }

    static async updateProfile(formData) {
        return await authApi
            .post("/" + apiPrefix + "/user/profile", formData)
            .then((response) => {
                // console.log(response);
                return response?.data?.data;
            });
    }

    static async updatePassword(formData) {
        return await authApi
            .post("/" + apiPrefix + "/user/profile/change-password", formData)
            .then((response) => {
                // console.log(response);
                return response?.data?.data;
            });
    }

    static async submitComplain(formData) {
        return await authApi
            .post("/" + apiPrefix + "/user/profile/submit-complain", formData)
            .then((response) => {
                // console.log(response);
                return response?.data?.data;
            });
    }

    static async submitBet(formData) {
        return await authApi
            .post("/" + apiPrefix + "/bet-submit", formData)
            .then((response) => {
                // console.log(response);
                return response?.data;
            });
    }

    static async submitPrediction(formData) {
        return await authApi
          .post("/" + apiPrefix + "/prediction-entry", formData)
          .then((response) => {
            // console.log(response);
            return response?.data;
          });
    }
}

export default UserService;