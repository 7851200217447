import React from "react";
import { Outlet } from "react-router-dom";
import SportsCategories from "../common/match/SportsCategories";
import iplGif from "../../assets/images/gif/ipl25.gif";

const HomeLayout = () => {
  return (
    <>
      <div className={`home-layout h-100`}>
        {/* <h3 className="live-in-play-header">Live In-Play</h3> */}
        <div className="home-layout-body">
          <div className="banner-area">
            <a href="/ipl-champion">
              <img src={iplGif} alt="ipl 2025 prediction" />
            </a>
          </div>
          <SportsCategories />
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default HomeLayout;
