import Modal from "react-bootstrap/Modal";
import iplBanner from "../../../assets/images/gif/ipl25_banner.gif";
import { Alert } from "react-bootstrap";

export default function HomePageModal(props) {

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Indian Premier League 2025
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert>Play & Win - 100% Withdraw Guarantee</Alert>
        <a href="/ipl-champion">
          <img src={iplBanner} />
        </a>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
}

// function App() {
//   const [modalShow, setModalShow] = React.useState(false);

//   return (
//     <>
//       <Button variant="primary" onClick={() => setModalShow(true)}>
//         Launch vertically centered modal
//       </Button>

//       <MyVerticallyCenteredModal
//         show={modalShow}
//         onHide={() => setModalShow(false)}
//       />
//     </>
//   );
// }

// render(<App />);
