import HomeLayout from '../components/layouts/HomeLayout';
import PreMatchLayout from '../components/layouts/PreMatchLayout';
import SiteLayout from '../components/layouts/SiteLayout';
import UserLayout from '../components/layouts/UserLayout';
import ForgotPassword from '../pages/forgot-password';
import Home from '../pages/home';
import MatchDetails from '../pages/match/match-details';
import PreMatch from '../pages/pre-match';
import PreMatchSportsDetails from '../pages/pre-match/sports-details';
import Register from '../pages/register';
import SignIn from '../pages/sign-in';
import SportsDetails from '../pages/sports/sports-details';
import BetHistory from '../pages/user/profile/bet-history';
import MyFollowers from '../pages/user/profile/my-followers';
import ProfileDetails from '../pages/user/profile/profile';
import UpdateClub from '../pages/user/profile/update-club';
import UpdatePassword from '../pages/user/profile/update-password';
import UpdateProfile from '../pages/user/profile/update-profile';
import ComplainHistory from '../pages/user/support/complain-history';
import SubmitComplain from '../pages/user/support/submit-complain';
import CoinTransfer from '../pages/user/wallet/coin-transfer';
import DepositHistory from '../pages/user/wallet/deposit-history';
import MakeDeposit from '../pages/user/wallet/make-deposit';
import Statements from '../pages/user/wallet/statement';
import Withdraw from '../pages/user/wallet/withdraw';
import WithdrawHistory from '../pages/user/wallet/withdraw-history';
import Error404 from '../pages/_error';
import Lottery from '../pages/lottery';

const websiteRoutes = [
  {
    path: "",
    layout: SiteLayout,
    children: [
      {
        path: "",
        layout: HomeLayout,
        children: [
          { path: "/", exact: true, name: "Home", element: Home },
          { path: "/in-play", exact: true, name: "In Play", element: Home },
          { path: "/sports/all", name: "Sports All", element: Home },
          {
            path: "/sports/:id",
            name: "Sports Details",
            element: SportsDetails,
          },
        ],
      },
    ],
  },

  {
    path: "",
    layout: SiteLayout,
    children: [
      {
        path: "/pre-match",
        layout: PreMatchLayout,
        children: [
          { path: "", exact: true, name: "Pre-Match", element: PreMatch },
          {
            path: "/sports/all",
            name: "Pre-Match Sports All",
            element: PreMatch,
          },
          {
            path: "/sports/:id",
            name: "Pre-Match Sports Details",
            element: PreMatchSportsDetails,
          },
        ],
      },
    ],
  },

  {
    path: "",
    layout: SiteLayout,
    children: [
      { path: "/match/:id", name: "Match Details", element: MatchDetails },
    ],
  },

  {
    path: "/profile",
    layout: UserLayout,
    children: [
      { path: "", name: "Profile Details", element: ProfileDetails },
      {
        path: "/view-profile",
        name: "Profile Details",
        element: ProfileDetails,
      },
      {
        path: "/update-profile",
        name: "Update Profile",
        element: UpdateProfile,
      },
      { path: "/update-club", name: "Update Club", element: UpdateClub },
      { path: "/my-followers", name: "My Followers", element: MyFollowers },
      { path: "/bet-history", name: "Bet History", element: BetHistory },
      {
        path: "/update-password",
        name: "Update Password",
        element: UpdatePassword,
      },
    ],
  },

  {
    path: "/wallet",
    layout: UserLayout,
    children: [
      { path: "/make-deposit", name: "Make Deposit", element: MakeDeposit },
      {
        path: "/deposit-history",
        name: "Deposit History",
        element: DepositHistory,
      },
      { path: "/coin-transfer", name: "Coin Transfer", element: CoinTransfer },
      { path: "/withdraw", name: "Withdraw", element: Withdraw },
      {
        path: "/withdraw-history",
        name: "Withdraw History",
        element: WithdrawHistory,
      },
      { path: "/statement", name: "Statements", element: Statements },
    ],
  },

  {
    path: "/support",
    layout: UserLayout,
    children: [
      {
        path: "/complain-history",
        name: "Complain History",
        element: ComplainHistory,
      },
      {
        path: "/submit-complain",
        name: "Submit Complain",
        element: SubmitComplain,
      },
    ],
  },

  { path: "/sign-in", name: "Sign In", element: SignIn },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    element: ForgotPassword,
  },
  { path: "/register", name: "Sign In", element: Register },
  { path: "/ipl-champion", name: "Lottery", element: Lottery },

  { path: "/404", name: "Error404", element: Error404 },
  { path: "*", name: "Error404", element: Error404 },
];

export default websiteRoutes;
