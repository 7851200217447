"use client";
import { IconCoin } from "@tabler/icons";
import { useEffect, useState } from "react";
import {
  Card,
  Button,
  Container,
  Row,
  Col,
  Form,
  InputGroup,
} from "react-bootstrap";
import { animated, useSpring } from "@react-spring/web";

import "../../../scss/CouponGrid.scss";
import SiteService from "../../../services/api/site.service";
import LoadingSpinner from "../../common/loader/LoadingSpinner";
import { connect, useDispatch } from "react-redux";
import { loadedLoggedIn, login, setUser } from "../../../redux/slices/auth";
import { setMessage } from "../../../redux/slices/message";
import UserService from "../../../services/api/user.service";
import { setUpdatedCoinsChange } from "../../../redux/slices/common";

function IPLTeamVoucher({
  user,
  refreshToken,
  updatedCoinsChange,
  setMessage,
}) {
  const displatch = useDispatch()
  const [eventInfo, setEventInfo] = useState();
  const [selectedTeam, setSelectedTeam] = useState();
  const [stakeAmount, setStakeAmount] = useState(500);
  const springs = useSpring({
    from: { transform: "scale(0)" },
    to: { transform: "scale(0.8)" },
    loop: true,
    delay: 700,
  });

  const iplTeams = [
    {
      id: "csk",
      name: "CSK",
      fullName: "Chennai Super Kings",
      color: "#FFCC00",
      textColor: "#004BA0",
      logo: "https://documents.iplt20.com/ipl/CSK/logos/Logooutline/CSKoutline.png",
    },
    {
      id: "dc",
      name: "DC",
      fullName: "Delhi Capitals",
      color: "#0078BC",
      textColor: "#FFFFFF",
      logo: "https://documents.iplt20.com/ipl/DC/Logos/LogoOutline/DCoutline.png",
    },
    {
      id: "gt",
      name: "GT",
      fullName: "Gujarat Titans",
      color: "#1C1C1C",
      textColor: "#FFFFFF",
      logo: "https://documents.iplt20.com/ipl/GT/Logos/Logooutline/GToutline.png",
    },
    {
      id: "kkr",
      name: "KKR",
      fullName: "Kolkata Knight Riders",
      color: "#3A225D",
      textColor: "#FFFFFF",
      logo: "https://documents.iplt20.com/ipl/KKR/Logos/Logooutline/KKRoutline.png",
    },
    {
      id: "lsg",
      name: "LSG",
      fullName: "Lucknow Super Giants",
      color: "#A72056",
      textColor: "#FFFFFF",
      logo: "https://documents.iplt20.com/ipl/LSG/Logos/Logooutline/LSGoutline.png",
    },
    {
      id: "mi",
      name: "MI",
      fullName: "Mumbai Indians",
      color: "#004BA0",
      textColor: "#FFFFFF",
      logo: "https://documents.iplt20.com/ipl/MI/Logos/Logooutline/MIoutline.png",
    },
    {
      id: "pbks",
      name: "PBKS",
      fullName: "Punjab Kings",
      color: "#ED1B24",
      textColor: "#FFFFFF",
      logo: "https://documents.iplt20.com/ipl/PBKS/Logos/Logooutline/PBKSoutline.png",
    },
    {
      id: "rr",
      name: "RR",
      fullName: "Rajasthan Royals",
      color: "#254AA5",
      textColor: "#FFFFFF",
      logo: "https://documents.iplt20.com/ipl/RR/Logos/Logooutline/RRoutline.png",
    },
    {
      id: "rcb",
      name: "RCB",
      fullName: "Royal Challengers Bengaluru",
      color: "#EC1C24",
      textColor: "#FFFFFF",
      logo: "https://documents.iplt20.com/ipl/RCB/Logos/Logooutline/RCBoutline.png",
    },
    {
      id: "srh",
      name: "SRH",
      fullName: "Sunrisers Hyderabad",
      color: "#FF822A",
      textColor: "#000000",
      logo: "https://documents.iplt20.com/ipl/SRH/Logos/Logooutline/SRHoutline.png",
    },
  ];

  const amounts = [500, 1000, 3000, 5000, 10000];

  const selectTeam = (id) => {
    setSelectedTeam(id === selectedTeam ? null : id);
  };

  const handleStakeChange = (e) => {
    // Allow only positive numbers
    const value = e.target.value.replace(/[^0-9]/g, "");
    setStakeAmount(value);
  };

  const placeBet = () => {
    if (!selectedTeam) {
      setMessage({
        type: "error",
        title: "Select a team",
      });
      return;
    }

    if (!stakeAmount || Number.parseInt(stakeAmount) <= 50) {
      setMessage({
        type: "error",
        title: "Stake amout is too low",
      });
      return;
    }

    const team = iplTeams.find((t) => t.id === selectedTeam);
    // alert(`Bet placed on ${team?.fullName} with stake amount ₹${stakeAmount}`);
    const formData = {
      participant_id: user.id,
      prediction_event_id: 1,
      stake: stakeAmount,
      prediction: selectedTeam,
    };

    UserService.submitPrediction(formData)
      .then((res) => {
        setMessage({
          type: "success",
          title: "Prediction Placed Successfully",
        });
        clearData();
        displatch(setUpdatedCoinsChange(100));
      })
      .catch((err) => {
        setMessage({
          type: "error",
          title: "Prediction not Placed",
        });
      });
  };

  const clearData = () => {
    setStakeAmount(0);
    setSelectedTeam(null);
  };

  const fetchEventData = async (id) => {
    SiteService.getPredictionEvent(id)
      .then((res) => setEventInfo(res))
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    fetchEventData(1);
    console.log(refreshToken, user);
  }, []);

  return (
    <Container className="py-4 mt-5" style={{ maxWidth: "450px" }}>
      {/* Mobile frame */}
      {eventInfo ? (
        <div className="border rounded-4 overflow-hidden shadow bg-white">
          {/* Header */}
          <div className="d-flex align-items-center px-3 py-2 border-bottom">
            <a
              href="/"
              variant="light"
              className="p-1 me-2"
              style={{ width: "32px", height: "32px" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-arrow-left"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                />
              </svg>
            </a>
            <div className="fs-5 fw-medium">{eventInfo?.name}</div>
          </div>

          {/* Main content */}
          <div
            style={{
              background: "linear-gradient(to bottom right, #6B0F8F, #FF4D8D)",
              padding: "1rem",
            }}
          >
            <div class="d-flex flex-row">
              <div
                className="text-white mb-3"
                dangerouslySetInnerHTML={{ __html: eventInfo?.description }}
              ></div>
              <animated.div
                className="text-center d-flex flex-column exclusive-text"
                style={{ ...springs }}
              >
                {/* CSS Promo Badge */}
                <div
                  className="d-flex justify-content-center mb-3"
                  style={{ marginTop: "-30px" }}
                >
                  <div
                    className="position-relative animate__animated animate__pulse animate__infinite animate__slow"
                    style={{
                      width: "150px",
                      height: "150px",
                    }}
                  >
                    <div
                      className="position-absolute top-0 start-0 w-100 h-100"
                      style={{
                        background:
                          "linear-gradient(135deg, #FF3366 0%, #FF9933 100%)",
                        borderRadius: "50%",
                        transform: "rotate(0deg)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        color: "white",
                        fontWeight: "bold",
                        textAlign: "center",
                        padding: "10px",
                        textShadow: "1px 1px 2px rgba(0,0,0,0.3)",
                        boxShadow: "0 0 20px rgba(255,255,255,0.4)",
                        clipPath:
                          "polygon(50% 0%, 61% 10%, 75% 0%, 85% 15%, 100% 25%, 90% 40%, 100% 50%, 90% 65%, 100% 75%, 85% 85%, 75% 100%, 60% 90%, 50% 100%, 40% 90%, 25% 100%, 15% 85%, 0% 75%, 10% 60%, 0% 50%, 10% 35%, 0% 25%, 15% 15%, 25% 0%, 40% 10%)",
                      }}
                    >
                      <div style={{ fontSize: "2rem", marginBottom: "-5px" }}>
                        Win
                      </div>
                      <div
                        style={{
                          fontSize: "2.5rem",
                          lineHeight: "1",
                          fontWeight: "900",
                        }}
                      >
                        {Math.round(eventInfo?.remuneration)}X
                      </div>
                      <div
                        style={{ fontSize: "12px", marginTop: "-2px" }}
                      ></div>
                    </div>
                  </div>
                </div>
              </animated.div>
            </div>
            {/* Title */}

            {/* Stake input */}
            <div className="bg-warning rounded p-3 mb-3">
              <Form.Label className="fw-bold">Enter Stake Amount</Form.Label>
              <InputGroup className="mb-2">
                <InputGroup.Text>
                  <IconCoin />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  value={stakeAmount}
                  onChange={handleStakeChange}
                  placeholder="Enter amount"
                  className="fw-bold fs-5"
                />
              </InputGroup>
              <div className="d-flex justify-content-between">
                {amounts.map((amount, idx) => (
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    onClick={() => setStakeAmount(amount)}
                    className="mx-2 bg-white"
                    key={idx}
                  >
                    {/* <IconCoin /> */}
                    {amount}
                  </Button>
                ))}
              </div>
            </div>

            {/* Select team label */}
            <div className="text-white mb-2 fw-bold">
              Select a Team to Bet On
            </div>

            {/* Team grid */}
            <Row className="g-2 mb-4">
              {iplTeams.map((team) => (
                <Col xs={4} key={team.id}>
                  <Card
                    onClick={() => selectTeam(team.id)}
                    style={{
                      backgroundColor: team.color,
                      color: team.textColor,
                      cursor: "pointer",
                      transform:
                        selectedTeam === team.id ? "scale(1.05)" : "scale(1)",
                      transition: "transform 0.2s",
                      border:
                        selectedTeam === team.id ? "3px solid white" : "none",
                      boxShadow:
                        selectedTeam === team.id
                          ? "0 0 10px rgba(255,255,255,0.5)"
                          : "none",
                    }}
                    className="h-100"
                  >
                    <Card.Body className="p-2 text-center">
                      <div className="d-flex justify-content-center mb-1">
                        <div
                          className="rounded-circle d-flex align-items-center justify-content-center overflow-hidden"
                          style={{
                            width: "40px",
                            height: "40px",
                            backgroundColor: "rgba(255, 255, 255, 0.2)",
                          }}
                        >
                          <img
                            src={team.logo}
                            alt={team.fullName}
                            width={40}
                            height={40}
                          />
                        </div>
                      </div>
                      <div className="fw-bold fs-5">{team.name}</div>
                      <div className="small text-truncate">{team.fullName}</div>
                      <div
                        className="small mt-2 rounded py-1"
                        style={{
                          backgroundColor: "rgba(255, 255, 255, 0.2)",
                          fontWeight:
                            selectedTeam === team.id ? "bold" : "normal",
                        }}
                      >
                        {selectedTeam === team.id ? "Selected" : "Select"}
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>

            {/* Place bet button */}
            {refreshToken ? (
              <Button
                onClick={placeBet}
                className="w-100 border-0 rounded-pill py-2 fw-bold fs-5 mb-2"
                style={{
                  background: "linear-gradient(to right, #FFC53D, #FF822A)",
                }}
                disabled={!selectedTeam || !stakeAmount}
              >
                Submit
              </Button>
            ) : (
              <Button
                // onClick={placeBet}
                className="w-100 border-0 rounded-pill py-2 fw-bold fs-5 mb-2"
                style={{
                  background: "linear-gradient(to right, #FFC53D, #FF822A)",
                }}
                // disabled={!selectedTeam || !stakeAmount}
              >
                Login to Submit
              </Button>
            )}

            {/* Footer text */}
            <div className="text-center small text-white-50 mt-2">
              Bet responsibly. You must be 18+ to place bets. Terms & conditions
              apply.
            </div>
          </div>
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </Container>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.auth.user,
  refreshToken: state.auth.auth.refreshToken,
  updatedCoinsChange: state.common.updatedCoinsChange,
});

export default connect(mapStateToProps, {
  setMessage,
})(IPLTeamVoucher);
