import React from "react";
import LotteryView from "../components/page/lottery/LotteryView";
import Header from "../components/common/header/Header";

function Lottery() {
  return (
    <div>
      <Header />
      <LotteryView />
    </div>
  );
}

export default Lottery;
