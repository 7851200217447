import axios from "axios";
import { apiPrefix, apiUrl } from "../../config/config";

class SiteService {

    static async getSiteNotice() {
        return await axios
            .get(apiUrl + "/" + apiPrefix + "/site-notices")
            .then((response) => {
                return response.data.data;
            });
    }

    static async getPredictionEvent(id) {
        return await axios
          .get(apiUrl + `/${apiPrefix}/getPredictionEvent/${id}`)
          .then((response) => {
            return response.data.data;
          });
    }
    
    
    static async getSiteSettings() {
        return await axios
            .get(apiUrl + "/" + apiPrefix + "/site-settings")
            .then((response) => {
                return response.data.data;
            });
    }
}

export default SiteService;